<template>
  <div>
    <div class="marginbottom25 margintop20">
      <div class="container">
        <div class="row">
          <div class="col-6 center">
            <p class="psemibold textcolor">{{ title }}</p>
          </div>
          <div class="col-6 col-right center">
            <input type="time" id="takingtime" name="takingtime" class="inputlist backgroundcolor-transparent fontcolor-tertiaryblue display-inline">
            <!--<p class="psmallregular textcolor display-inline opacity80" style="margin-left:10px">Uhr</p> -->
          </div>
        </div>
      </div>
      <div class="trennlinie-light"></div>
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: ['title', 'fill']
}
</script>
