<template>
  <div class="bodywhite">
    <div class="container">
      <div class="paddingtop80">
        <h1 class="fontcolor-mainblue">Wann bedeutet bei dir <br> frühs, mittags, abends <br> und nachts?</h1>
        <p class="pregular fontcolor-mainblue">Definiere deine täglichen Einnahmezeiten.</p>
      </div>
    </div>
    <div class="paddingtop40">
      <list-element-input title="Früh" fill="8:00"></list-element-input>
      <list-element-input title="Mittag" fill="13:00"></list-element-input>
      <list-element-input title="Abend" fill="18:00"></list-element-input>
      <list-element-input title="Nacht" fill="22:00"></list-element-input>
    </div>

    <div class="stepnavigation">
      <div class="container">
        <button type="button" value="Submit" class="buttonwhite" onclick="">
          <a class="col-2 col-center center" href="registrationusername">
              <img class="navicon:active" src="../assets/icons/arrow.svg">
          </a>
        </button>
        <a href="registrationmedication">
          <button type="button" value="Submit" class="button" onclick="move()">
            <p class="pbold display-inline fontcolor-white">Weiter</p>
            <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
          </button>
        </a>
      </div>
    </div>

    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="registrationtimezuregistrationusername()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="registrationusertimezuregistrationmedication()">
          <p class="pbold display-inline fontcolor-white">Weiter</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped>
::placeholder {
  color: #7C8489;
  opacity: 1;
}

</style>


<script>
  import { mapActions } from 'vuex';
  import router from '../router';
  import RegistrationListelementInputVue from "../components/RegistrationListelementInput.vue";

  export default {
    name: 'registrationtime',
    components: {
      'list-element-input' :RegistrationListelementInputVue,
    },
    methods: {
      ...mapActions(['loadUserData']),
      registrationtimezuregistrationusername() {
        router.push({path: 'registrationusername'})
      },
      registrationusertimezuregistrationmedication() {
        router.push({path: 'registrationmedication'})
      },
    }
  };
</script>